<div class="app"
     #app
     appTheme>
    <router-outlet></router-outlet>

    @if (blockService.isShowModal) {
        <app-block-ui [hidden]="isHideBlockModal"
                      (closeEvent)="isHideBlockModal = true"></app-block-ui>
    }

    @if (expiredPopup$ | async) {
        <app-expired-password></app-expired-password>
    }

    @if ((notes$ | async) && (notes$ | async)?.length) {
        <div class="app__note">
            <app-note-notifications *ngFor="let noteInfo of (notes$ | async)" [noteInfo]="noteInfo"
                                    (closeEvent)="destroyNote(noteInfo)"></app-note-notifications>
        </div>
    }
</div>

<app-popup *ngIf="isNewAppVersion" [title]="'app.newVersionTitle'" (closeEmit)="isNewAppVersion = false">
    <div class="popup__text">
        <div class="popup__message" [translate]="'app.newVersionMessage'"></div>
        <div class="popup__btns">
            <button class="popup__btn btn btyn--light" [translate]="'btns.cancel'"
                    (click)="isNewAppVersion = false"></button>
            <button class="popup__btn btn btn--red" [translate]="'btns.reload'" (click)="reloadPage()"></button>
        </div>
    </div>
</app-popup>
