<div class="login__page">
    <div class="login">
        <div class="login__wrapper">
            <h2 class="login__title" [translate]="'auth.change.title'"></h2>
            <form class="login__form" [formGroup]="form" novalidate>
                <ng-container *ngFor="let item of formArray">

                    <ng-container *ngIf="item.name === formTypeEnum.password">

                        <div class="login__form-item">
                            <label class="login__form-label" [translate]="item.title"></label>
                            <input
                                [type]="item.isVisible ? 'text' : 'password'"
                                [class.login__form-input--middle]="strength === 1"
                                [class.login__form-input--strong]="strength > 1"
                                (keydown.backspace)="lookChangePasswordInput()"
                                [ngModel]="modelPassword"
                                [appInputValidate]="item.validateName"
                                class="login__form-input"
                                [placeholder]="item.placeholder | translate"
                                (input)="isEmptyForm = false"
                                [formControlName]="item.name">
                            <div class="login__icon-eyes" (click)="item.isVisible = !item.isVisible">
                                <svg-icon
                                    [src]="!item.isVisible ? './assets/design/icons/eyes.svg' : './assets/design/icons/eyes-close.svg'"></svg-icon>
                            </div>
                            <p class="login__form-error"
                               [class.login__form-error--middle]="strength === 1"
                               [class.login__form-error--default]="strength === -1"
                               [class.login__form-error--error]="strength === 0
                               || (strength === -1 && form.controls['password'].invalid && form.controls['password'].touched)"
                               [class.login__form-error--success]="strength > 1">
                                <span *ngIf="strength < 1" [translate]="'auth.registration.passwordNote'"></span>
                                <span *ngIf="strength === 1" [translate]="'auth.registration.passwordBetter'"></span>
                                <span *ngIf="strength === 2" [translate]="'auth.registration.passwordBest'"></span>
                            </p>

                        </div>

                    </ng-container>


                    <ng-container *ngIf="item.name === formTypeEnum.repeat">

                        <div class="login__form-item">
                            <label class="login__form-label" [translate]="item.title"></label>
                            <input class="login__form-input"
                                   [placeholder]="item.placeholder | translate"
                                   [formControlName]="item.name"
                                   [type]="item.isVisible ? 'text' : 'password'"
                                   [appInputValidate]="item.validateName"
                                   (input)="isEmptyForm = false">
                            <div class="login__icon-eyes" (click)="item.isVisible = !item.isVisible">
                                <svg-icon
                                    [src]="!item.isVisible ? './assets/design/icons/eyes.svg' : './assets/design/icons/eyes-close.svg'"></svg-icon>
                            </div>
                            <p *ngIf="form.controls['repeat'].invalid && form.controls['repeat'].touched && !isEmptyForm"
                               class="login__form-error"
                               [translate]="'auth.registration.errorRepeatPassword'"></p>
                            <p *ngIf="isEmptyForm" class="login__form-error"
                               [translate]="'auth.registration.requireError'"></p>

                        </div>

                    </ng-container>

                </ng-container>

                <div class="login__form-activity">
                    <div class="login__form-activity-item">
                        <div class="login__form-activity-column">
                            <button
                                [translate]="'btns.cancel'"
                                class="login__btn btn btn--light"
                                (click)="moveToLogin()"></button>
                            <button
                                [translate]="'btns.create'"
                                [disabled]="!form.controls['password'].valid || !form.controls['repeat'].valid || isWaitRequest"
                                class="login__form-sign-btn"
                                (click)="createPassword()"></button>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>

