export const themes = {
    light: {
        '--baseFontColor': '#505055',
        '--placeholderColor': '#AAAAAA',
        '--moreBlueColor': '#14A2E4',
        '--inputDisableColor': '#747474',
        '--inputBorder': '1px solid #929CA6',
        '--inputBg': '#F8F8F8',
        '--inputColor': '#64666E',
        '--inputBorderColor': '#B9B8B9',
        '--inputBorderBottom': '#AAAAAA',
        '--inputBorderMiddle': '1px solid #E9A000',
        '--inputBorderStrong': '1px solid #28895E',
        '--formLinkColor': '#14A2E4',
        '--authPrimaryBtnColor': '#FA6D55',
        '--authPrimaryBtnColorHover': '#ED492E',
        '--authPrimaryBtnColorText': '#FFFFFF',
        '--authBgColor': '#ffffff',
        '--authBgImage': "url('/assets/design/img/auth-bg-light.jpg')",
        '--authWrapperBg': '#FFFFFF',
        '--authLnBg': '#5B7098',
        '--authSignBtnBg': '#E46B78',
        '--errorColor': '#F80A2D',
        '--attentionColor': '#E9A000',
        '--successColor': '#28895E',
        '--checkboxBackgroundColor': '#6C757D',
        '--checkboxSvgColor': '#FFFFFF',
        '--urlCheckbox': `url("../design/icons/checked-dark.svg")`,
        '--navLogoColor': '#5B7098',

        '--baseWrapperShadow': 'rgba(42, 44, 47, 0.6)',

        '--bgPreload': '#fff',

        '--calendarHoverBg': '#DADADA',
        '--calendarDisableHoverColor': '#DADADA',

        // PreloaderGroupColor
        '--preloaderColor': 'rgb(100, 102, 110)',

        // calendar
        '--calendarPickedBg': '#E7EFFF',
        '--calendarPickedColor': '#FFFFFF',
        '--calendarDayColor': '#505055',
        '--calendarStartEndBgColor': '#3B5999',
        '--calendarDisabledColor': '#AAAAAA',

        // ElementDisable
        '--disableColor': '#747474',
        '--disableIconColor': '#ddd',

        '--checkBoxWaitingBg': '#FFEED1',
        '--checkBoxErrorBg': '#F6DDDD',

        //  login
        '--loginColor': '#505055',

        // profile
        '--profileIconColor': '#6C757D',
        '--profileInputBg': '#f3f4f5',
        '--profileInputColor': '#000',
        '--subtitleColor': '#8E8E8E',

        // pagination
        '--paginationBgColor': 'transparent',
        '--paginationBorderColor': '#5B7098',
        '--paginationActiveColor': '#A1D3E7',
        '--paginationCurrentBg': '#5B7098',
        '--paginationCurrentColor': '#F3F4F5',
        '--paginationCurrentBorder': '#536FA3',
        '--paginationHoverEffects': 'rgba(208, 233, 243, 0.5)',
        '--paginationArrowColor': '#1D2B3A',
        '--paginationTextHoverColor': '#1D2B3A',
        '--paginationArrowNextUrl': `url('/assets/design/icons/pagination-next.svg')`,
        '--paginationArrowPrevUrl': `url('/assets/design/icons/pagination-prev.svg')`,
        '--paginationArrowNextHoverUrl': `url('/assets/design/icons/pagination-next-hover.svg')`,
        '--paginationArrowPrevHoverUrl': `url('/assets/design/icons/pagination-prev-hover.svg')`,
        '--paginationSelectArrow': `url('/assets/design/icons/pagination-select-arrow-light.svg')`,

        // sidebar, navbar
        '--burgerColor': '#5B7098',
        '--footerBgColor': '#D8D9DC',
        '--footerBgColorMob': '#D8D9DC',
        '--footerColor': '#324465',
        '--contentBgColor': '#F6F6F6',
        '--contentSideBgColor': '#F6F6F6',
        '--sidebarBgColor': '#5B7098',
        '--breadcrumbsColor': '#6C757D',
        '--breadcrumbsTextColor': '#64666E',
        '--navArrowUrl': `url('/assets/design/icons/nav-arrow-light.svg')`,
        '--navBorder': '1px solid #536FA3',
        '--navMobIcon': '#536FA3',

        // Popup
        '--popupIconBg': '#324465',
        '--popupIconHoverBg': '#EEF0F5',
        '--popupFontColor': '#324465',
        '--popupBg': '#EEF0F5',
        '--popupItemHover': '#324465',
        '--popupItemHoverColor': '#EDEFF3',
        '--toggleBg': '#EDEFF3',
        '--togglePointBg': '#324465',
        '--toggleBorder': '0.5px solid #929CA6',

        //  navbar
        '--navBgColor': '#F1F1F1',
        '--navLinkColor': '#324465',
        '--navLinkHover': '#324465',
        '--navLogoUrl': `url('/assets/design/icons/logo-nav.svg')`,
        '--navIconColor': '#383D45',

        // filters
        '--radioColor': '#6C757D',
        '--radioColorDisabled': '#AAAAAA',
        '--selectBackgound': '#F3F3F3',
        '--selectBackgoundHover': '#ECF3FF',
        '--selectBackgoundBorderHover': '#536FA3',
        '--selectBorder': '1px solid #929CA6',
        '--selectBorderHover': '1px solid #536FA3',
        '--selectBorderDisabled': '1px solid #D9D9D9',
        '--selectBorderColorDisabled': '#D9D9D9',
        '--selectArrowUrl': `url(/assets/design/icons/select-arrow-light.svg)`,
        '--selectArrowUrlDisabled': `url(/assets/design/icons/select-arrow-light-disabled.svg)`,
        '--selectOptionHover': '#9FC1E1',
        '--filtersBorder': '1px solid #929CA6',
        '--filterDisabledBorder': '1px solid #D9DBDC',
        '--filterCheckboxDisabledBg': '#AAAAAA',
        '--filterCheckboxBg': '#6C757D',
        '--disabledBtnColor': '#E2E2E2',
        '--disabledBtnTextColor': '#BFC2C9',
        '--filtersBtnBgColor': '#536FA3',
        '--filtersBtnBgHoverColor': '#3B5999',
        '--filtersBtnTextColor': '#F3F3F3',
        '--filtersBtnResetBgColor': '#FFFFFF',
        '--filtersBtnResetTextColor': '#536FA3',
        '--filtersBtnResetTextColorHover': '#536FA3',
        '--filtersBtnResetBorder': '1px solid #536FA3',
        '--filtersBtnResetHover': '#E4EDFF',
        '--filtersDatepickerBg': '#F5F5F5',
        '--filtersDateIconUrl': `url("/assets/design/icons/date-icon.svg")`,
        '--filtersDateIconFocusUrl': `url("/assets/design/icons/date-icon-foc.svg")`,
        '--filtersDateIconColor': '#434956',
        '--datepickerNext': `url("/assets/design/icons/datepicker-move-prev.svg")`,
        '--datepickerPrev': `url("/assets/design/icons/datepicker-move-next.svg")`,
        '--disabledFontColor': '#AAAAAA',
        '--disabledBgColor': '#F5F5F5',
        '--radioBtnColor': '#AAAAAA',
        '--radioBtnChecked': '#6C757D',
        '--chartIconColor': '#6C757D',
        '--chartLegendDisabledColor': '#AAA',

        // table
        '--tableBorder': '1px solid #CCCCCC',
        '--tableBorderTop': '2px solid #B6B6B6',
        '--tableThBg': '#EEF1FA',
        '--tableArrowDownColor': '#AAAAAA',
        '--tableArrowDownColorActive': '#536FA3',
        '--tableTrBgEven': '#F8F8F8',
        '--tableTrBgOdd': '#F3F4F5',
        '--tableTrHover': '#FFFFFF',
        '--tableTrHoverBorder': '2px 2px 5px #CCCCCC',
        '--tableTrShadow': '0px 2px 3px rgba(204, 204, 204, 0.6)',
        '--tableTrBgAttention': '#FFEED1',
        '--tableTrBgAttentionHover': '#F5A92D',
        '--tableTrBgAlarm': '#F6DDDD',
        '--tableTrBgAlarmHover': '#F26A6A',
        '--tableAddItemBg': '#F0F2F9',
        '--tableAddItemBgBorder': '#DDDDDD',
        '--ackBgColor': '#FFECEC',
        '--ackFontColor': '#FF0000',
        '--ackBorder': '1px solid #FF6161',
        '--tableTextColor': '#64666E',
        '--tableHeading': '#64666E',
        '--tableTr': '#F8F8F8',
        '--tableOnlineColor': '#D4EFDA',
        '--tableTrHeading': '#EEF1FA',
        '--tableColumnsIcon': '#F25D5D',
        '--tableTrBg': '#F8F8F8',
        '--tableTrDisable': '#E7E7E7',
        '--tableTrDisableColor': '#AAAAAA',
        '--tableRegistratorRowBg': '#E4F0F5',

        '--tableTrDisableBg': '#E7E7E7',

        '--tableStatusIconBg': '#F8F8F8',
        '--tableStatusIconColor': '#64666E',

        '--tableAlarmBgHover': '#F4A4A4',

        '--tableStatusArrowActiveColor': '#536FA3',

        '--tableErrorBg': '#FDDDDD',

        '--tableActionIconColor': '#64666E',

        //  tabs
        '--tabsBorderColor': '#4990E2',
        '--tabsBorderHoverColor': '#ECF3FF',

        // mnemonic
        '--mnemonicSidebarBackground': '#fff',
        '--mnemonicSidebarColor': '#505055',
        '--mnemonicSidebarIcon': '#64666E',
        '--mnemonicSidebarItemHover': '#ECF3FF',

        //  Invite
        '--inviteWrapperBg': '#FFF3C8',
        '--mallingInviteWrapperBg': '#E3EEFF',
        '--inviteWrapperBorder': '#CCCCCC',
        '--inviteWrapperColor': '#505055',

        // InviteBtnClose
        '--inviteBtnCloseBg': '#F1F1F1',
        '--inviteBtnCloseBgHover': '#ECF3FF',
        '--inviteBtnCloseColor': '#536FA3',
        '--inviteBtnCloseBorderColor': '#536FA3',

        // InviteBtnOk
        '--inviteBtnOkBg': '#536FA3',
        '--inviteBtnOkBgHover': '#3B5999',
        '--inviteBtnOkColor': '#EDEFF3',
        '--inviteBtnOkBorderColor': '#536FA3',

        //  columnMailing
        '--columnMailingInputBg': '#FFFFFF',
        '--columnMailingInputColor': '#000',
        '--columnMailingInputBorder': '#CCCCCC',
        '--columnMailingIconColor': '#64666E',
        '--columnMailingSelectBg': '#F8F8F8',
        '--columnMailingGroupColor': '#64666E',
        '--columnMailingSelectHover': '#ECF3FF',
        '--columnMailingSelectHoverColor': '#536FA3',
        '--columnMailingBtn': '#536FA3',
        '--columnMailingGroupBg': '#F0EEEE',
        '--columnMailingPopupHeading': '#E8E8E8',
        '--columnMailingPopupBg': '#F6F6F6',
        '--columnMailingPopupHeadingColor': '#505055',
        '--columnMailingPopupHover': '#E5E8F9',
        '--columnMailingBackWrapper': 'rgba(42, 44, 47, 0.6)',
        '--columnMailingError': '#F80A2D',
        '--columnMailingEditIconBg': '#fff',
        '--columnMailingEditIconColor': '#5B7098',

        '--columnMailingEditAddIconColor': '#5B7098',
        '--columnMailingEditAddIcon': '#EDEFF3',

        '--columnTextIcon': '#64666E',
        '--columnTextViewIcon': '#64666E',
        '--columnInputBorder': '#cfd1d4',

        '--columnTextRevert': '#5B7098',

        '--columnTextEditIconColor': '#536FA3',
        '--columnIconOffline': '#AAAAAA',

        '--columnAcknColor': '#FF0000',
        '--columnAcknBg': '#FFECEC',
        '--columnAcknBorder': '#FF6161',

        '--columnEditorTextAreaColor': '#64666E',

        // inputConstructor
        '--inputConstructorBg': '#F4F3F8',
        '--inputConstructorBorder': '#C8CACE',
        '--inputConstructorColor': '#777880',
        '--inputConstructorInputColor': '#F8F8F8',
        '--inputConstructorInputBorder': '#929CA6',
        '--inputConstructorIconBg': '#536FA3',
        '--inputConstructorIconColor': '#BFC2C9',
        '--inputConstructorIconDisableBg': '#E2E2E2',
        '--inputConstructorIconDisableColor': '#BFC2C9',

        // sidebar
        '--sidebarIconColor': '#EDEFF3',
        '--sidebarItemColorHover': '#34BFA3',
        '--sidebarItemBgColor': '#324465',

        // error message component
        '--errorBgColor': '#FF6161',

        // error alert component
        '--alertFontColor': '#1D2B3A',

        // block ui
        '--bgShadowColor': 'rgba(42, 44, 47, 0.6)',
        '--bgWrapperColor': '#FFFFFF',
        '--bgHeaderBg': '#E8E8E8',
        '--blockTitleColor': '#505055',
        '--blockBtnLoginBg': '#536FA3',
        '--blockBtnLogoutBg': '#FFFFFF',
        '--blockBtnBorder': '1px solid #536FA3',
        '--blockLogoutBtnTextColor': '#536FA3',
        '--blockLoginBtnTextColor': '#FFFFFF',

        // devices table
        '--deviceDisabled': '#AAAAAA',
        '--deviceGreen': '#0FA579',
        '--deviceRed': '#FF6161',
        '--deviceYellow': '#FCC02A',
        '--deviceTooltipBorder': '1px solid #929CA6',
        '--deviceTooltipBorderColor': '#929CA6',
        '--deviceTooltipBg': '#FFEFAB',
        '--deviceTooltipColor': '#1D2B3A',

        '--userDeviceConnectColor': '#0FA579',
        '--userDeviceCellOk': '#0FA579',
        '--userDeviceCellAlarm': '#FF6161',
        '--userDeviceCellAttention': '#F9852C',

        '--userDeviceSignalAttention': '#F5A623',

        '--userDeviceOffline': '#424348',
        '--userDeviceIsActiveBg': '#E7E7E7',
        '--userDeviceIsActiveBorder': '#B6B6B6',
        '--userDeviceIsActiveColor': '#AAAAAA',
        '--userDeviceIsActiveIconColor': '#AAAAAA',
        '--userDeviceIconTypeColor': '#0FA579',

        '--deviceCellOkBg': '#D0ECE7',
        '--deviceCellOkShadow': '#B6EBD9',
        '--deviceCellOkColor': '#0FA579',
        '--deviceCellOkBorder': '2px solid #0FA579',

        '--deviceInActiveBg': '#F0EDED',
        '--deviceInActiveShadow': '#C5C3C6',
        '--deviceInActiveColor': '#64666E',
        '--deviceInActiveBorder': '#C5C3C6',
        '--deviceInActiveHover': '#C5C3C6',
        '--deviceInActiveIconSetColor': '#505055',
        '--deviceInActiveIconPointColor': '#64666E',

        '--deviceCellAlarmBg': '#F6DDDD',
        '--deviceCellAlarmShadow': '#F9AEAE',
        '--deviceCellAlarmBorder': '2px solid #FF6161',

        '--deviceCellAlarmColor': '#FF6161',

        '--deviceCellAttentionBorder': '2px solid #F5A92D',
        '--deviceCellAttentionColor': '#F5A623',
        '--deviceCellAttentionBg': '#FFEED1',
        '--deviceCellAttentionHover': '#FAD496',
        '--deviceCellAttentionActiveBg': '#F5A92D',
        '--deviceCellAttentionActiveBgHover': '#F5A92D',
        '--deviceCellAttentionShadow': '#F5A623',

        '--deviceIconActTrue': '#34BFA3',
        '--deviceIconActFalse': '#D9D9D9',
        '--deviceEditSvg': '#5F6281',
        '--deviceDeleteSvg': '#F25D5D',

        '--deviceSignalAlarmBg': 'none',
        '--deviceSignalAlarmBorder': 'none',
        '--deviceSignalAttentionBg': 'none',
        '--deviceSignalAttentionBorder': 'none',

        '--shareDeviceDisableBtnBg': '#E2E2E2',
        '--shareDeviceDisableBtnColor': '#BFC2C9',

        '--shareDeviceBtnHover': '#3B5999',

        '--shareDeviceError': '#F80A2D',

        //  setting
        '--settingHeaderBg': '#F0EEEE',
        '--configuratorWrapperBg': '#F8F8F8',

        // messagesCatalog
        '--messageTextAreaBorder': '#929CA6',

        // group
        '--groupDefaultBorder': '2px solid #C5C3C6;',
        '--groupDefaultHeaderBG': '#CCCCCC',
        '--groupOk': '#C5E2AD',
        '--groupOkColor': '#505055',
        '--groupOkActive': '#5EA820',
        '--groupOkHover': '#5EA820',
        '--groupOkItemHover': '#AFD490',
        '--groupOkItemHoverColor': '#64666E',
        '--groupOkHoverColor': '#505055',
        '--groupSleep': '#479EFE1A',
        '--groupSleepActive': '#479EFE',
        '--groupAttention': '#FFEED1',
        '--groupAttentionActive': '#F5A92D',
        '--groupAttentionActiveHover': '#F5A92D',
        '--groupError': '#FDDDDD',
        '--groupErrorActive': '#F25D5D',
        '--groupErrorHover': '#F9AEAE',
        '--groupIconSettings': '#666870',
        '--groupIconSettingsActive': '#FFFFFF',
        '--groupBg': '#F8F8F8',
        '--groupShowAllColorActive': '#979797',
        '--groupShowAllColor': '#505055',
        '--groupVariableColor': '#64666e',

        '--groupLinkColor': '#4990E2',
        '--groupLinkColorHover': '#536FA3',

        // heading
        '--headingTitle': '#64666E',
        '--headingWrapper': '#F0EEEE',
        '--headingIcon': '#64666E',
        '--layoutBg': '#D8D8D8',
        '--layoutBgHover': '#d8d8d86b',

        // customSelect
        '--customSelectBg': '#F8F8F8',
        '--customSelectDefaultColor': '#979797',
        '--customSelectTitle': '#5B7098',
        '--customSelectHoverBg': '#ECF3FF',
        '--customSelectHoverBorder': '#536FA3',
        '--customSelectHoverColor': '#536FA3',
        '--customSelectArrow': '#536FA3',
        '--customSelectIconColor': '#5B7098',
        '--customSelectIconHover': '#536FA3',
        '--customSelectIconDisable': '#BFC2C9',

        // notification
        '--notificationBg': '#F8F8F8',
        '--notificationBorderLeft': '#CCCCCC',

        // chanel
        '--notificationChanelBg': '#F8F8F8',
        '--notificationChanelBorder': '#CCCCCC',
        '--notificationChanelHover': '#FFFFFF',
        '--notificationChanelActive': '#5B7098',
        '--notificationChanelActiveColor': '#fff',
        '--notificationChanelIcon': '#536FA3',
        '--notificationChanelIsRead': '#D7ECE8',

        // dialog
        '--notificationDialogBg': '#F3F4F5',
        '--notificationDialogColor': '#505055',
        '--notificationDialogShadow': '0px 1px 5px rgba(67, 84, 116, 0.2)',

        // message
        '--notificationMessageBgMine': '#E5E8F9',
        '--notificationMessageBg': '#EBEBEB',
        '--notificationMessageShadow': '-0.5px 1px 5px rgba(0, 0, 0, 0.25)',
        '--messageDisableBtnBg': '#E2E2E2',
        '--messageDisableBtnColor': '#BFC2C9',

        // sendler
        '--notificationSendlerBg': '#F3F4F5',
        '--notificationSendlerColor': '#AAAAAA',
        '--notificationSendlerBorder': '#AAAAAA',
        '--notificationSendlerScroll': '#FAFAFA',
        '--notificationSendlerShadow': '0px -1px 5px rgba(67, 84, 116, 0.2)',
        '--notificationSendlerBtn': '#536FA3',
        '--notificationSendlerBtnColor': '#F6F6F6',
        '--notificationSendlerBtnHover': '#3B5999',
        '--notificationSendlerBtnDisable': '#E2E2E2',
        '--notificationSendlerBtnDisableColor': '#BFC2C9',

        // CreateChannel
        '--notificationCreateInputBg': '#fff',
        '--notificationCreateInputColor': '#505055',

        '--notificationCreateBtnColor': '#fff',

        // multilang
        '--multilangBorderLeft': '#cccccc',

        // CreateReport
        '--createReportColor': '#64666E',
        '--createReportBtnPrint': '#BFC2C9',
        '--createReportBtnPrintIcon': '#fff',
        '--createReportBtnPrintBorder': '#fff',
        '--createReportBtnPrintDisable': '#E2E2E2',
        '--createReportBtnPrintDisableIcon': '#BFC2C9',
        '--createReportBtnPrintDisableBorder': 'none',
        '--createReportPopupColor': '#1D2B3A',
        '--createReportCheckboxColor': '#AAAAAA',

        //  treeNodeWrapper
        '--treeNodeWrapper': '#E7E8EF',

        //  Btn
        '--btnBg': '#536FA3',
        '--btnColor': '#EDEFF3',
        '--btnBorder': 'none',
        '--btnHover': '#3B5999',

        //  Disable
        '--btnDisableBg': '#E2E2E2',
        '--btnDisableColor': '#858688',
        '--btnDisableBorder': 'none',

        //  -red
        '--btnRedBg': '#E46B78',
        '--btnRedColor': '#EDEFF3',
        '--btnRedHover': '#C64B58',

        //  -light
        '--btnLightBg': '#F8F8F8',
        '--btnLightColor': '#5B7098',
        '--btnLightBorder': '#536FA3',
        '--btnLightBgHover': '#ECF3FF',

        //  shareDevice
        '--shareDeviceHeading': '#E8E8E8',
        '--shareDeviceBg': '#F6F6F6',
        '--shareDeviceColor': '#64666E',
        '--shareDeviceIconColor': '#64666E',
        '--shareDeviceInputColor': '#64666E',
        '--shareDeviceTextAreaBg': '#E2E2E2',
        '--shareDeviceCounterColor': '#797979',
        '--shareDeviceNewBg': 'rgba(157, 228, 173, 0.38)',
        // '--shareDeviceError': '#F80A2D',
        '--shareDeviceTextAreaBorder': '#E2E2E2',
        '--shareDeviceTextAreaColor': '#64666E',

        // eventLog
        '--eventLogAlarmBg': '#F4E0E0',
        '--eventLogAlarmColor': '#FF6161',
        '--eventLogAlarmBorder': '#FF6161',

        '--eventLogAttentionBg': '#FCF1DA',
        '--eventLogAttentionColor': '#F9852C',
        '--eventLogAttentionBorder': '#F9852C',

        '--eventLogAttentionHoverBg': '#FBD595',
        '--eventLogAttentionHoverColor': 'none',
        '--eventLogAttentionHoverBorder': 'none',

        //  LogicalEvent
        '--unActiveIconColor': '#DADADA',

        //    Scrollbar
        '--scrollbarBg': '#FAFAFA',
        '--scrollbarThumb': '#C1C1C1',
        '--scrollbarTrack': '#FAFAFA',

        //  chartSetting
        '--chartBgColor': 'transparent',
        '--chartLineColor': '#333',
        '--chartLineChColor': '#e0dada',
        '--chartErrorBg': 'rgba(255, 239, 239, 0.7)',
        '--chartErrorBorder': '1px solid #F25D5D',
        '--chartErrorColor': '#B83636',
        '--chartRereadingIconBg': 'transparent',
        '--chartRereadingIconColor': '#536FA3',

        // Transport
        '--transportAlarmBg': '#FF6257',
        '--transportAlarmColor': '#FF6257',
        '--transportAlarmBorder': '#FF6257',

        '--transportAttentionBg': '#FA963B',
        '--transportAttentionColor': '#FA963B',
        '--transportAttentionBorder': '#FA963B',

        '--transportOkBg': '#0FA579',
        '--transportOkColor': '#0FA579',
        '--transportOkBorder': '#0FA579',

        '--transportColor': '#333',

        '--transportOfflineBorder': '#AAA',

        '--transportDisabledColor': '#CFCFCF',

        '--transportHoverBg': 'rgba(216, 216, 216, 0.35)',
        '--transportChartIconColor': '#536FA3',

        // Tree
        '--treItemHover': '#FFFFFF',
        '--treItemHoverShadow': '0px 2px 3px rgba(204, 204, 204, 0.6)',
        '--treItemHoverActive': '#E5E8F9',

        // Device log
        '--deviceLogIconEditColor': '#5B7098',
        '--deviceLogIconEditBg': '#F8F8F8',
        '--deviceLogIconEditBorder': '#AAAAAA',

        // Input
        '--disabledColorInput': '#aaa',

        // skeleton
        '--backgroundSkeleton': '#f0eeee',

        // digital signature
        '--backgroundDropBg': '#F0F0F1',
        '--backgroundDropBgHover': '#E6E7ED',

        // events table
        '--backgroundRowAlarm': '#F8E6E6',
        '--backgroundRowAlarmHover': '#F4A4A4',
        '--backgroundRowAttention': '#FDF6E9',
        '--backgroundRowAttentionHover': '#FBD595',

        // role constructor
        '--roleConstructorMainBg': '#ECF3FF',
        '--roleConstructorMainShadow': '0px 2px 2px rgba(15, 19, 41, 0.1)',
        '--roleConstructorChildHover': '#fff',
        '--roleConstructorChildShadow': '0px 2px 3px rgba(204, 204, 204, 0.6)',

        // datalogger dashboard

        '--dataloggerDashboardBg': '#fff',
        '--dataloggerDashboardBorder': '#E1E3E1',
        '--dataloggerDashboardAlarmIconBg': '#F1F1F4',
        '--dataloggerDashboardAlarmIconColor': '#77819C',
        '--contactOddBg': '#F3F4F5',
        '--contactInputBg': 'transparent',
        '--toggleButtonBg': '#FFFFFF',
        '--toggleButtonBorder': '#0070F2',
    },
    dark: {
        '--baseFontColor': '#C6C6C6',
        '--placeholderColor': '#AAAAAA',
        '--moreBlueColor': '#14A2E4',
        '--inputDisableColor': '#dadada',
        '--inputBorder': '1px solid #686D6D',
        '--inputBg': '#27292D',
        '--inputColor': '#C6C6C6',
        '--inputBorderColor': '#929CA6',
        '--inputBorderBottom': '#929CA6',
        '--inputBorderMiddle': '1px solid #E9A000',
        '--inputBorderStrong': '1px solid #28895E',
        '--formLinkColor': '#4EB1F1',

        '--baseWrapperShadow': 'rgba(95, 98, 104, 0.58)',

        '--bgPreload': '#000',

        '--calendarHoverBg': '#B3D8FF',

        // PreloaderGroupColor
        '--preloaderColor': '#fff',

        // calendar
        '--calendarPickedBg': 'rgba(179, 216, 255, 0.31)',
        '--calendarPickedColor': '#505055',
        '--calendarDayColor': '#DADADA',
        '--calendarStartEndBgColor': '#B3D8FF',
        '--calendarDisabledColor': '#838383',

        //  ElementDisable
        '--disableColor': '#858688',
        '--disableIconColor': '#64666E',

        '--checkBoxWaitingBg': 'transparent',
        '--checkBoxErrorBg': 'transparent',

        //  login
        '--loginColor': '#C6C6C6',

        // auth
        '--authPrimaryBtnColor': '#FA6D55',
        '--authPrimaryBtnColorHover': '#993F4C',
        '--authPrimaryBtnColorText': '#F6F6F6',
        '--authBgColor': '#27292F',
        '--authBgImage': "url('/assets/design/img/auth-bg-dark.png')",
        '--authWrapperBg': '#27292F',
        '--authLnBg': '#46505A',
        '--authSignBtnBg': '#C24D5C',
        '--errorColor': '#FF6161',
        '--attentionColor': '#F8981D',
        '--successColor': '#34BFA3',
        '--checkboxBackgroundColor': '#DDDDDD',
        '--checkboxSvgColor': '#1D2B3A',
        '--urlCheckbox': `url("../design/icons/checked-dark.svg")`,
        '--navLogoColor': '#C6C6C6',

        // pagination
        '--paginationBgColor': 'transparent',
        '--paginationBorderColor': '#434956',
        '--paginationActiveColor': '#383D45',
        '--paginationCurrentBg': '#B3D8FF',
        '--paginationCurrentColor': '#383D45',
        '--paginationCurrentBorder': '#B3D8FF',
        '--paginationHoverEffects': '#434956',
        '--paginationArrowColor': '#A1D3E7',
        '--paginationTextHoverColor': '#383D45',
        '--paginationArrowNextUrl': `url('/assets/design/icons/pagination-next-hover-dark.svg')`,
        '--paginationArrowPrevUrl': `url('/assets/design/icons/pagination-prev-hover-dark.svg')`,
        '--paginationArrowNextHoverUrl': `url('/assets/design/icons/pagination-next-hover-dark.svg')`,
        '--paginationArrowPrevHoverUrl': `url('/assets/design/icons/pagination-prev-hover-dark.svg')`,
        '--paginationSelectArrow': `url('/assets/design/icons/pagination-select-arrow-dark.svg')`,

        // profile
        '--profileIconColor': '#C6C6C6',
        '--profileInputBg': '#212226',
        '--profileInputColor': '#C6C6C6',
        '--subtitleColor': '#8E8E8E',

        //  navbar
        '--navBgColor': '#2C2E34',
        '--navLinkColor': '#C6C6C6',
        '--navLinkHover': '#46505A',
        '--navLogoUrl': `url('/assets/design/icons/logo-nav-dark.svg')`,
        '--navIconColor': '#C6C6C6',
        '--navArrowUrl': `url('/assets/design/icons/nav-arrow-dark.svg')`,
        '--navBorder': '1px solid #383D45',
        '--navMobIcon': '#B3D8FF',

        //  navbar popup
        '--popupIconBg': '#F3F4F5',
        '--popupIconHoverBg': '#EEF0F5',
        '--popupBg': '#46505A',
        '--popupItemHover': '#338877',
        '--popupItemHoverColor': '#EDEFF3',
        '--popupFontColor': '#F3F4F5',

        // sidebar
        '--burgerColor': '#DDDDDD',
        '--footerBgColor': '#2A2D33',
        '--footerBgColorMob': '#3B4146',
        '--footerColor': '#c6c6c6',
        '--contentBgColor': '#282C33',
        '--contentSideBgColor': '#24252A',
        '--sidebarBgColor': '#383D45',
        '--breadcrumbsColor': '#C6C6C6',
        '--breadcrumbsTextColor': '#C6C6C6',
        '--toggleBg': '#434956',
        '--togglePointBg': '#FCC02A',
        '--toggleBorder': '0.5px solid #929CA6',

        // filters
        '--radioColor': '#DDDDDD',
        '--radioColorDisabled': '#686D6D',
        '--selectBackgound': '#3F4652',
        '--selectBackgoundHover': '#30333A',
        '--selectBackgoundBorderHover': '#B3D8FF',
        '--selectBorder': '1px solid #929CA6',
        '--selectBorderHover': '1px solid #B3D8FF',
        '--selectBorderDisabled': '1px solid #434956',
        '--selectBorderColorDisabled': '#434956',
        '--selectArrowUrl': `url(/assets/design/icons/select-arrow-dark.svg)`,
        '--selectArrowUrlDisabled': `url(/assets/design/icons/select-arrow-dark-disabled.svg)`,
        '--selectOptionHover': '#27292F',
        '--filtersBorder': '1px solid #929CA6',
        '--filterDisabledBorder': '1px solid #434956',
        '--filterCheckboxDisabledBg': '#686D6D',
        '--filterCheckboxBg': '#DDDDDD',
        '--disabledBtnColor': '#32343A',
        '--disabledBtnTextColor': '#858688',
        '--filtersBtnBgColor': '#B3D8FF',
        '--filtersBtnBgHoverColor': '#85C0FE',
        '--filtersBtnTextColor': '#333',
        '--filtersBtnResetBgColor': 'transparent',
        '--filtersBtnResetTextColor': '#B3D8FF',
        '--filtersBtnResetTextColorHover': '#B3D8FF',
        '--filtersBtnResetBorder': '1px solid #B3D8FF',
        '--filtersBtnResetHover': 'rgba(161, 185, 231, 0.2)',
        '--filtersDatepickerBg': '#27292F',
        '--filtersDateIconUrl': `url("/assets/design/icons/date-icon-dark.svg")`,
        '--filtersDateIconFocusUrl': `url("/assets/design/icons/date-icon-foc-dark.svg")`,
        '--filtersDateIconColor': '#aaa',
        '--datepickerNext': `url("/assets/design/icons/datepicker-move-prev-dark.svg")`,
        '--datepickerPrev': `url("/assets/design/icons/datepicker-move-next-dark.svg")`,
        '--disabledFontColor': '#858688',
        '--disabledBgColor': '#27292F',
        '--radioBtnColor': '#84868A',
        '--radioBtnChecked': '#C6C6C6',
        '--chartIconColor': '#C6C6C6',
        '--chartLegendDisabledColor': '#858688',

        // table
        '--tableBorder': '1px solid #424348',
        '--tableBorderTop': '2px solid #64666E',
        '--tableThBg': '#30343B',
        '--tableArrowDownColor': '#C6C6C6',
        '--tableArrowDownColorActive': '#A1D3E7',
        '--tableTrHover': '#121212',
        '--tableTrHoverBorder': '2px 2px 5px rgba(20, 20, 21, 0.9)',
        '--tableTrBgEven': '#27292F',
        '--tableTrBgOdd': '#2A2D33',
        '--tableTrShadow': '0px 3px 4px rgba(35, 37, 42, 0.6)',
        '--tableTrBgAttention': '#595239',
        '--tableTrBgAttentionHover': '#D5AC46',
        '--tableTrBgAlarm': '#573D40',
        '--tableTrBgAlarmHover': '#D26464',
        '--tableAddItemBg': '#3A4557',
        '--tableAddItemBgBorder': '#929CA6',
        '--ackBgColor': '#F6DCD3',
        '--ackFontColor': '#FE1B1A',
        '--ackBorder': '1px solid transparent',
        '--navBorderButtom': '1px solid #383D45',
        '--tableHeading': '#BABABA',
        '--tableTr': '#3B414E',
        '--tableTextColor': '#E6DADA',
        '--tableOnlineColor': '#3E5C5F',
        '--tableTrHeading': '#30343B',
        '--tableColumnsIcon': '#C6C6C6',
        '--tableTrBg': '#2A2D33',
        '--tableTrDisable': '#2D2E31',
        '--tableRegistratorRowBg': '#3B414E',

        '--tableTrDisableBg': '#2D2E31',
        '--tableTrDisableColor': '#646668',

        '--tableStatusIconBg': '#46505A',

        '--tableAlarmBgHover': '#913C3F',

        '--tableStatusArrowActiveColor': '#85C0FE',

        '--tableErrorBg': '#573D40',

        '--tableActionIconColor': '#C6C6C6',

        //  tabs
        '--tabsBorderColor': '#85C0FE',
        '--tabsBorderHoverColor': 'rgba(115, 166, 220, 0.18)',

        // mnemonic
        '--mnemonicSidebarBackground': '#212226',
        '--mnemonicSidebarColor': '#C6C6C6',
        '--mnemonicSidebarIcon': '#B3D8FF',
        '--mnemonicSidebarItemHover': '#46505A',

        //  Invite
        '--inviteWrapperBg': '#537D8A',
        '--mallingInviteWrapperBg': '#3F5069',
        '--inviteWrapperBorder': '#424348',
        '--inviteWrapperColor': '#EDEFF3',

        // InviteBtnClose
        '--inviteBtnCloseBg': 'transparent',
        '--inviteBtnCloseBgHover': 'rgba(161, 185, 231, 0.2)',
        '--inviteBtnCloseColor': '#B3D8FF',
        '--inviteBtnCloseBorderColor': '#B3D8FF',

        // InviteBtnOk
        '--inviteBtnOkBg': '#B3D8FF',
        '--inviteBtnOkBgHover': '#85C0FE',
        '--inviteBtnOkColor': '#27292D',
        '--inviteBtnOkBorderColor': '#B3D8FFh',

        //  columnMailing
        '--columnMailingInputBg': '#212226',
        '--columnMailingInputColor': '#C6C6C6',
        '--columnMailingInputBorder': '#686D74',
        '--columnMailingIconColor': '#C6C6C6',
        '--columnMailingSelectBg': '#3F4652',
        '--columnMailingSelectHover': '#30333A',
        '--columnMailingSelectHoverColor': '#B3D8FF',
        '--columnMailingBtn': '#B3D8FF',
        '--columnMailingGroupBg': '#30343B',
        '--columnMailingGroupColor': '#C6C6C6',
        '--columnMailingPopupHeading': '#373A40',
        '--columnMailingPopupBg': '#2D3036',
        '--columnMailingPopupHeadingColor': '#C6C6C6',
        '--columnMailingPopupHover': '#3E5C5F',
        '--columnMailingBackWrapper': 'rgba(95, 98, 104, 0.58)',
        '--columnMailingError': '#FF6161',
        '--columnMailingEditIconBg': '#34373D',
        '--columnMailingEditIconColor': '#5B5C60',

        '--columnMailingEditAddIconColor': '#B3D8FF',
        '--columnMailingEditAddIcon': '#27292D',

        '--columnTextIcon': '#64666E',
        '--columnTextViewIcon': '#C6C6C6',
        '--columnInputBorder': '#424348',

        '--columnTextRevert': '#B3D8FF',

        '--columnTextEditIconColor': '#479EFE',

        '--columnIconOffline': '#64666E',

        '--columnAcknColor': '#FF6161',
        '--columnAcknBg': '#302C2F',
        '--columnAcknBorder': '#FF6161',

        '--columnEditorTextAreaColor': '#C6C6C6',

        '--columnDateSettingItem': '#536FA3',

        // multilang

        '--multilangBorderLeft': '#929CA6',

        // inputConstructor
        '--inputConstructorBg': '#46505A',
        '--inputConstructorBorder': '#383D45',
        '--inputConstructorColor': '#C6C6C6',
        '--inputConstructorInputColor': '#212226',
        '--inputConstructorInputBorder': '#383D45',
        '--inputConstructorIconBg': '#B3D8FF',
        '--inputConstructorIconColor': '#BFC2C9',
        '--inputConstructorIconDisableBg': '#34373D',
        '--inputConstructorIconDisableColor': '#5B5C60',

        // sidebar
        '--sidebarIconColor': '#A1D3E7',
        '--sidebarItemColorHover': '#338877',
        '--sidebarItemBgColor': '#556272',

        // error message component
        '--errorBgColor': '#FF6161',

        // error alert component
        '--alertFontColor': '#1D2B3A',

        // block ui
        '--bgShadowColor': 'rgba(71, 77, 85, 0.6)',
        '--bgWrapperColor': '#27292F',
        '--bgHeaderBg': '#373A40',
        '--blockTitleColor': '#C6C6C6',
        '--blockBtnLoginBg': '#536FA3',
        '--blockBtnLogoutBg': '#2F3239',
        '--blockBtnBorder': '1px solid #536FA3',
        '--blockLogoutBtnTextColor': '#DDDDDD',
        '--blockLoginBtnTextColor': '#FFFFFF',

        // devices table
        '--deviceDisabled': '#686D6D',
        '--deviceGreen': '#0FA579',
        '--deviceRed': '#FF6161',
        '--deviceYellow': '#FCC02A',
        '--deviceTooltipBg': '#CBB56F',
        '--deviceTooltipBorder': '1px solid #424348',
        '--deviceTooltipBorderColor': '#424348',
        '--deviceTooltipColor': '#1D2B3A',

        '--userDeviceConnectColor': '#1EC892',
        '--userDeviceCellOk': '#1EC892',
        '--userDeviceCellAlarm': '#FF6161',
        '--userDeviceCellAttention': '#F9852C',

        '--userDeviceSignalAttention': '#F9852C',

        '--userDeviceOffline': '#424348',
        '--userDeviceIsActiveBg': '#2D2E31',
        '--userDeviceIsActiveBorder': '#424348',
        '--userDeviceIsActiveColor': '#64666E',
        '--userDeviceIsActiveIconColor': '#64666E',
        '--userDeviceIconTypeColor': '#C6C6C6',

        '--deviceCellOkBg': '#2D544B',
        '--deviceCellOkShadow': '#267246',
        '--deviceCellOkColor': '#1EC892',
        '--deviceCellOkBorder': '2px solid #1EC892',

        '--deviceInActiveBg': '#3A3B3E',
        '--deviceInActiveShadow': '#5B5C60',
        '--deviceInActiveColor': '#CCCCCC',
        '--deviceInActiveBorder': '#5B5C60',
        '--deviceInActiveHover': '#3A3B3E',
        '--deviceInActiveIconSetColor': '#CCCCCC',
        '--deviceInActiveIconPointColor': '#CCCCCC',

        '--deviceCellAlarmBg': '#573D40',
        '--deviceCellAlarmShadow': '#5EA820',
        '--deviceCellAlarmBorder': '2px solid #FF6161',
        '--deviceCellAlarmColor': '#FF6161',

        '--deviceCellAttentionColor': '#CD9C20',
        '--deviceCellAttentionBg': '#595239',
        '--deviceCellAttentionHover': '#55452D',
        '--deviceCellAttentionActiveBg': '#B77B26',
        '--deviceCellAttentionActiveBgHover': '#55452D',
        '--deviceCellAttentionShadow': '#B77B26',
        '--deviceCellAttentionBorder': '2px solid #CD9C20',

        '--deviceIconActTrue': '#34BFA3',
        '--deviceIconActFalse': '#686D6D',
        '--deviceEditSvg': '#A1D3E7',
        '--deviceDeleteSvg': '#F25D5D',

        '--deviceSignalAlarmBg': '#573D40',
        '--deviceSignalAlarmBorder': '#FF6161',
        '--deviceSignalAttentionBg': '#595239',
        '--deviceSignalAttentionBorder': '#F9852C',

        '--shareDeviceDisableBtnBg': '#34373D',
        '--shareDeviceDisableBtnColor': '#5B5C60',

        '--shareDeviceBtnHover': '#85C0FE',

        //  setting
        '--settingHeaderBg': '#373A40',
        '--configuratorWrapperBg': '#27292D',

        // messagesCatalog
        '--messageTextAreaBorder': '#424348',

        // group
        '--groupDefaultBorder': '2px solid #CCCCCC',
        '--groupDefaultHeaderBG': '#CCCCCC',
        '--groupOk': '#293A35',
        '--groupOkColor': '#BABABA',
        '--groupOkActive': '#5EA820',
        '--groupOkHover': '#5EA820',
        '--groupOkItemHover': '#2F422F',
        '--groupOkItemHoverColor': '#fff',
        '--groupOkHoverColor': '#fff',
        '--groupSleep': '#479EFE1A',
        '--groupSleepActive': '#479EFE',
        '--groupAttention': '#55452D',
        '--groupAttentionActive': '#B77B26',
        '--groupAttentionActiveHover': '#B77B26',
        '--groupError': '#543639',
        '--groupErrorActive': '#AB4044',
        '--groupErrorHover': '#543639',
        '--groupIconSettings': '#DADADA',
        '--groupIconSettingsActive': '#FFFFFF',
        '--groupBg': '#27292D',
        '--groupShowAllColorActive': '#64666E',
        '--groupShowAllColor': '#C6C6C6',
        '--groupVariableColor': '#fff',

        '--groupLinkColor': '#85C0FE',
        '--groupLinkColorHover': '#85C0FE',

        // heading
        '--headingTitle': '#C6C6C6',
        '--headingWrapper': '#373A40',
        '--headingIcon': '#DADADA',
        '--layoutBg': '#B3D8FF',
        '--layoutBgHover': '#85C0FE',

        // customSelect
        '--customSelectBg': '#3F4652',
        '--customSelectDefaultColor': '#AAAAAA',
        '--customSelectTitle': '#CCCCCC',
        '--customSelectHoverBg': '#30333A',
        '--customSelectHoverBorder': '#B3D8FF',
        '--customSelectHoverColor': '#B3D8FF',
        '--customSelectArrow': '#C8C8C8',
        '--customSelectIconColor': '#C6C6C6',
        '--customSelectIconHover': '#B3D8FF',
        '--customSelectIconDisable': '#5B5C60',

        // notification
        '--notificationBg': '#282C33',
        '--notificationBorderLeft': '#383D45',

        // chanel
        '--notificationChanelBg': '#2D3036',
        '--notificationChanelBorder': '#383D45',
        '--notificationChanelHover': '#46505A',
        '--notificationChanelActive': '#1D1F22',
        '--notificationChanelActiveColor': '#C6C6C6',
        '--notificationChanelIcon': '#C6C6C6',
        '--notificationChanelIsRead': '#3E5C5F',

        // dialog
        '--notificationDialogBg': '#2F3339',
        '--notificationDialogColor': '#C6C6C6',
        '--notificationDialogShadow': '0px 2px 5px rgba(1, 1, 1, 0.2)',

        // message
        '--notificationMessageBgMine': '#46505A',
        '--notificationMessageBg': '#34373E',
        '--notificationMessageShadow': '0px 2px 4px rgba(0, 0, 0, 0.2)',
        '--messageDisableBtnBg': '#40424A',
        '--messageDisableBtnColor': '#858688',

        // sendler
        '--notificationSendlerBg': '#2A2D33',
        '--notificationSendlerColor': '#C6C6C6',
        '--notificationSendlerBorder': '#929CA6',
        '--notificationSendlerScroll': '#5A6365',
        '--notificationSendlerShadow': '0px -2px 10px rgba(0, 0, 0, 0.2)',
        '--notificationSendlerBtn': '#96C8ED',
        '--notificationSendlerBtnColor': '#27292D',
        '--notificationSendlerBtnHover': '#88B4F7',
        '--notificationSendlerBtnDisable': '#34373D',
        '--notificationSendlerBtnDisableColor': '#858688',
        // CreateChannel
        '--notificationCreateInputBg': '#27292D',
        '--notificationCreateInputColor': '#C6C6C6',
        '--notificationCreateBtnColor': '#F6F6F6',

        // CreateReport
        '--createReportColor': '#C6C6C6',
        '--createReportBtnPrint': '#fff',
        '--createReportBtnPrintIcon': '#333',
        '--createReportBtnPrintBorder': '#B3D8FF',
        '--createReportBtnPrintDisable': '#34373D',
        '--createReportBtnPrintDisableIcon': '#5B5C60',
        '--createReportBtnPrintDisableBorder': '1px solid #34373D',
        '--createReportPopupColor': '#C6C6C6',
        '--createReportCheckboxColor': '#C6C6C6',

        // treeNodeWrapper
        '--treeNodeWrapper': '#34373E',

        //  Btn
        '--btnBg': '#B3D8FF',
        '--btnColor': '#333',
        '--btnBorder': 'none',
        '--btnHover': '#85C0FE',

        //  Disable
        '--btnDisableBg': '#34373D',
        '--btnDisableColor': '#5B5C60',
        '--btnDisableBorder': '#34373D',

        //  -red
        '--btnRedBg': '#C24D5C',
        '--btnRedColor': '#F3F4F5',
        '--btnRedHover': '#993F4C',

        // -light
        '--btnLightBg': 'transparent',
        '--btnLightColor': '#B3D8FF',
        '--btnLightBorder': '#B3D8FF',
        '--btnLightBgHover': 'rgba(161, 185, 231, 0.2)',

        // shareDevice
        '--shareDeviceHeading': '#373A40',
        '--shareDeviceBg': '#27292D',
        '--shareDeviceColor': '#C6C6C6',
        '--shareDeviceIconColor': '#C6C6C6',
        '--shareDeviceInputColor': '#C6C6C6',
        '--shareDeviceTextAreaBg': 'transparent',
        '--shareDeviceTextAreaBorder': '#929CA6',
        '--shareDeviceCounterColor': '#84868A',
        '--shareDeviceNewBg': '#3E5C5F',
        '--shareDeviceError': '#FF6161',
        '--shareDeviceTextAreaColor': '#C6C6C6',

        // eventLog
        '--eventLogAlarmBg': '#573D40',
        '--eventLogAlarmColor': '#FF6161',
        '--eventLogAlarmBorder': '#FF6161',

        '--eventLogAttentionBg': '#595239',
        '--eventLogAttentionColor': '#F5A623',
        '--eventLogAttentionBorder': '#F5A623',

        '--eventLogAttentionHoverBg': '#AF8038',
        '--eventLogAttentionHoverColor': '#F3F4F5',
        '--eventLogAttentionHoverBorder': '#424348',

        //  LogicalEvent
        '--unActiveIconColor': '#64666E',

        //    Scrollbar
        '--scrollbarBg': '#2A2D33',
        '--scrollbarThumb': '#5A6365',
        '--scrollbarTrack': '#2A2D33',

        //  chartSetting
        '--chartBgColor': 'transparent',
        '--chartLineColor': '#fff',
        '--chartLineChColor': '#45484f',
        '--chartErrorBg': '#302C2F',
        '--chartErrorBorder': '1px solid #AB4044',
        '--chartErrorColor': '#FF6161',
        '--chartRereadingIconBg': 'transparent',
        '--chartRereadingIconColor': '#B3D8FF',

        // Transport
        '--transportAlarmBg': '#FF6161',
        '--transportAlarmColor': '#FF6161',
        '--transportAlarmBorder': '#FF6161',

        '--transportAttentionBg': '#F5A623',
        '--transportAttentionColor': '#F5A623',
        '--transportAttentionBorder': '#F5A623',

        '--transportOkBg': '#1EC892',
        '--transportOkColor': '#1EC892',
        '--transportOkBorder': '#1EC892',

        '--transportColor': '#C6C6C6',

        '--transportOfflineBorder': '#646668',
        '--transportDisabledColor': '#646668',

        '--transportHoverBg': '#34373E',
        '--transportChartIconColor': '#B3D8FF',

        // Tree
        '--treItemHover': '#34373E',
        '--treItemHoverShadow': 'none',
        '--treItemBgActive': '#46505A',

        // Device log
        '--deviceLogIconEditColor': '#B3D8FF',
        '--deviceLogIconEditBg': '#2A2D33',
        '--deviceLogIconEditBorder': '#929CA6',

        // Input
        '--disabledColorInput': '#DADADA',

        // skeleton
        '--backgroundSkeleton': '#27292F',

        // digital signature
        '--backgroundDropBg': 'transparent',
        '--backgroundDropBgHover': '#46505A',

        // events table
        '--backgroundRowAlarm': '#49373B',
        '--backgroundRowAlarmHover': '#7C383B',
        '--backgroundRowAttention': '#4D4733',
        '--backgroundRowAttentionHover': '#96733E',

        // role constructor
        '--roleConstructorMainBg': '#3B414E',
        '--roleConstructorMainShadow': '0px 2px 2px rgba(15, 19, 41, 0.1)',
        '--roleConstructorChildHover': '#191A1C',
        '--roleConstructorChildShadow': '2px 2px 5px rgba(20, 20, 21, 0.9), inset -1px -1px 2px rgba(20, 20, 21, 0.5)',

        // datalogger dashboard

        '--dataloggerDashboardBg': '#33373E',
        '--dataloggerDashboardBorder': '#33373E',
        '--dataloggerDashboardAlarmIconBg': '#474B51',
        '--dataloggerDashboardAlarmIconColor': '#C6C8DA',
        '--contactOddBg': '#40444B',
        '--contactInputBg': '#46505a',
        '--toggleButtonBg': '#0070F2',
        '--toggleButtonBorder': '#fff',
    },
};
