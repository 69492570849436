import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../_helpers/must-math.validator';
import { ValidatePassword } from '../_helpers/strong-validator-password';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { HTTP_STATUS } from 'src/app/app-shared-elements/_enums/status.enum';
import { Store } from '@ngxs/store';
import { CheckToken, ResetPassword } from '../_store/actions/auth.actions';
import { AuthState } from '../_store/states/auth.state';
import { TypeTokenEnum } from '../_enums/type-token.enum';
import { FormTypeEnum } from '../../app-shared-elements/_enums/form-type.enum';
import { VariableValueTypeEnum } from '../../groups/_enums/variable-value-type.enum';

@Component({
    selector: 'app-reset',
    templateUrl: './reset.component.html',
    styleUrls: ['./reset.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetComponent implements OnInit {
    formArray: { name: FormTypeEnum; title: string; isVisible: boolean; validateName: VariableValueTypeEnum }[] = [
        {
            name: FormTypeEnum.password,
            title: 'auth.reset.password',
            isVisible: false,
            validateName: VariableValueTypeEnum.password,
        },
        {
            name: FormTypeEnum.repeat,
            title: 'auth.reset.repeat',
            isVisible: false,
            validateName: VariableValueTypeEnum.password,
        },
    ];

    strength = -1;

    form: FormGroup;

    isValidToken = true;
    isTwoFactorAuth = false;
    authToken: string;
    parseTypeToken: TypeTokenEnum;
    typeTokenEnum = TypeTokenEnum;

    formTypeEnum = FormTypeEnum;

    constructor(private formBuilder: FormBuilder, private router: Router, private store: Store, private r: ActivatedRoute) {}

    async ngOnInit(): Promise<void> {
        this.r.queryParams.pipe(first()).subscribe(async (params) => {
            await this.store.dispatch(new CheckToken(params.token, this.router.url.includes('control'))).toPromise();
            const result = this.store.selectSnapshot(AuthState.getAuthStatus);

            this.parseTypeToken = JSON.parse(atob(params.token.split('.')[1])).typeToken;

            this.isValidToken =
                result && (result.status === HTTP_STATUS.SUCCESS || result.status === HTTP_STATUS.TWO_FACTOR_AUTHENTICATION_REQUIRED);
            if (result.status === HTTP_STATUS.TWO_FACTOR_AUTHENTICATION_REQUIRED) {
                this.isTwoFactorAuth = true;
            }
        });

        this.form = this.formBuilder.group(
            {
                password: new FormControl('', [Validators.required, Validators.minLength(8), ValidatePassword.bind(this)]),
                repeat: new FormControl('', [Validators.required, Validators.minLength(8)]),
                twoAuth: new FormControl(''),
            },
            {
                validator: MustMatch('password', 'repeat'),
            },
        );
    }

    async reset(): Promise<void> {
        this.r.queryParams.pipe(first()).subscribe(async (params) => {
            await this.store
                .dispatch(
                    new ResetPassword(
                        { token: params.token, password: this.form.value.password, tokenTwoFactor: this.authToken },
                        this.router.url.includes('control'),
                    ),
                )
                .toPromise();
        });
    }

    moveToForgot(): void {
        this.router.url.includes('control') ? this.router.navigate(['login/control/forgot']) : this.router.navigate(['login/forgot']);
    }
}
