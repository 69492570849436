import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { ForgotComponent } from './auth/forgot/forgot.component';
import { ResetComponent } from './auth/reset/reset.component';
import { LoginComponent } from './auth/login/login.component';
import { ResetPasswordGuard } from './auth/_guards/reset-password.guard';
import { LoginGuardService } from './auth/_guards/login-guard.service';
import { ConfirmComponent } from './auth/confirm/confirm.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { CheckConfirmComponent } from './check-confirm/check-confirm.component';
import { BillingInvoicePrintComponent } from './billing/billing-invoice-print/billing-invoice-print.component';
import { RegistrationComponent } from './auth/registration/registration.component';

export const routes: Routes = [
    {
        path: 'login',
        component: AuthComponent,
        data: { title: 'auth' },
        canActivate: [LoginGuardService],
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: LoginComponent,
                data: { title: 'login' },
            },
            {
                path: 'registration',
                component: RegistrationComponent,
                data: { title: 'Registration' },
            },
            {
                path: 'forgot',
                component: ForgotComponent,
                data: { title: 'Forgot password' },
            },
            {
                path: 'reset',
                component: ResetComponent,
                data: { title: 'reset password' },
            },
            {
                path: 'confirm',
                component: ConfirmComponent,
                data: { title: 'Confirm' },
            },
            {
                path: 'control',
                data: { title: 'Control' },
                children: [
                    {
                        path: '',
                        component: LoginComponent,
                        data: { title: 'Confirm', isAdmin: true },
                    },
                    {
                        path: 'confirm',
                        component: ConfirmComponent,
                        data: { title: 'Confirm' },
                    },
                    {
                        path: 'change',
                        component: ChangePasswordComponent,
                        data: { title: 'Change password' },
                    },
                    {
                        path: 'forgot',
                        component: ForgotComponent,
                        data: { title: 'Confirm', isAdmin: true },
                    },
                    {
                        path: 'reset',
                        component: ResetComponent,
                        data: { title: 'Confirm' },
                    },
                ],
            },
        ],
    },
    {
        path: 'check-confirm',
        data: { title: 'check-confirm' },
        component: CheckConfirmComponent,
    },
    {
        path: 'invoice-server',
        data: { title: 'invoice print' },
        component: BillingInvoicePrintComponent,
    },
    {
        path: '**',
        redirectTo: '404',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            scrollPositionRestoration: 'enabled',
        }),
    ],
    exports: [RouterModule],
    providers: [ResetPasswordGuard],
})
export class AppRoutingModule {}
