import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HTTP_STATUS } from '../app-shared-elements/_enums/status.enum';
import { ApiResponse } from '../app-shared-elements/_interfaces/ApiRequest';
import { NotificationTransportType } from '../mailing/_interfaces/notification-log.interface';
import { UserNotificationTransport } from '../profile/_interfaces/user-notification-transport.interface';

@Component({
    selector: 'app-check-confirm',
    templateUrl: './check-confirm.component.html',
    styleUrls: ['./check-confirm.component.scss'],
})
export class CheckConfirmComponent implements OnInit, OnDestroy {
    title: string;
    message: string;
    t: NotificationTransportType;
    isError = false;

    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private http: HttpClient,
    ) {}

    ngOnInit(): void {
        this.route.queryParamMap.pipe(takeUntil(this.destroy)).subscribe(async (param: ParamMap) => {
            try {
                const token = param.get('token');
                if (token) {
                    const result: ApiResponse = (await this.http
                        .patch('/api/auth/confirm-transport', { token })
                        .toPromise()
                        .catch((e) => console.log(e))) as ApiResponse;
                    if (!result || result.status !== HTTP_STATUS.SUCCESS) {
                        this.isError = true;
                        return;
                    }
                } else {
                    this.isError = true;
                    return;
                }
                const transportData: UserNotificationTransport = JSON.parse(atob(token.split('.')[1]));
                this.t = transportData ? transportData.transportType : null;
            } catch (e) {
                this.isError = true;
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
    }

    moveToProfile(): void {
        this.router.navigate(['/profile']);
    }
}
