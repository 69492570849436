import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ConfirmToken } from '../_store/actions/auth.actions';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit, OnDestroy {
    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        private router: Router,
        private store: Store,
        private route: ActivatedRoute,
    ) {
        this.route.queryParamMap.pipe(first()).subscribe((query: ParamMap) => {
            const token = query.get('token');
            if (token) {
                this.store.dispatch(new ConfirmToken(token, this.router.url.includes('control')));
            } else {
                this.router.url.includes('control') ? this.router.navigate(['/login/control']) : this.router.navigate(['/login']);
            }
        });
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
    }

    moveLogin(): void {
        this.router.url.includes('control') ? this.router.navigate(['/login/control']) : this.router.navigate(['/login']);
    }
}
