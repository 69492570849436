import {FinanceDebtorsState} from "./states/finance-debtors.state";
import {FinanceInvoiceState} from "./states/finance-invoice.state";
import {FinanceServicesState} from "./states/finance-services.state";
import {FinancePayInfoState} from "./states/finance-pay-info.state";

export const financeState = [
    FinanceDebtorsState,
    FinanceInvoiceState,
    FinanceServicesState,
    FinancePayInfoState
];
