import {ChartSettingsState} from './states/chart-settings.state';
import {DeviceMapState} from './states/device-map.state';
import {GroupsState} from './states/groups.state';
import {ChartState, ErrorMessageChartState} from './states/chart.state';
import {ControlGroupsState} from './states/control-groups.state';
import {GroupDashboardState} from './states/groups-dashboard.state';
import {TableGroupsState} from './states/table-groups.state';
import {GeneralSettingsGroupState} from './states/general-settings.state';

export const groupsStates = [
    ErrorMessageChartState,
    GroupsState,
    ChartSettingsState,
    DeviceMapState,
    ChartState,
    ControlGroupsState,
    GroupDashboardState,
    TableGroupsState,
    GeneralSettingsGroupState
];
