import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiResponse } from '../../app-shared-elements/_interfaces/ApiRequest';
import { HTTP_STATUS } from '../../app-shared-elements/_enums/status.enum';
import { SocketService } from '../../app-shared-elements/_services/socket.service';
import { Store } from '@ngxs/store';
import { GetRolePermissionsForAdmin, GetRolePermissionsForUser } from 'src/app/app-shared-elements/_store/actions/permissions.actions';
import { PreloaderService } from 'src/app/app-shared-elements/_services/preloader.service';
import { PreloaderWrappersEnum } from 'src/app/app-shared-elements/_enums/preloader-wrappers.enum';
import { SetSkeleton } from 'src/app/app-shared-elements/_store/actions/table.actions';
import { AuthSingIn, ConfirmEmail, ForgotAdminPassword, ForgotPassword, SetIsAdmin, SetUserToken } from '../_store/actions/auth.actions';
import { AuthState } from '../_store/states/auth.state';
import { Platform } from '@angular/cdk/platform';
import { Observable, Subject } from 'rxjs';
import { NotificationsService } from '../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../app-shared-elements/_enums/tooltip-status.enum';
import { SetExpiredPopup } from '../../app-shared-elements/_store/actions/expired-password.actions';
import { first, takeUntil } from 'rxjs/operators';
import { PermissionsState } from '../../app-shared-elements/_store/states/permissions.state';
import { ResourceAction } from '../../app-shared-elements/_enums/permission.enum';
import { RolesService } from '../../admin/roles/_services/roles.service';
import { RemoveNote } from '../../app-shared-elements/_store/actions/note-notification.actions';
import { VariableValueTypeEnum } from '../../groups/_enums/variable-value-type.enum';
import { DisabledTypeEnum } from '../../app-shared-elements/_enums/disabled-type.enum';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    @ViewChild('rememberCheckbox') rememberCheckbox: ElementRef;

    authentication = false;
    passwordExpired = false;
    isVisiblePassword = false;
    isWaitRequest = false;

    readOnly = true;

    isContinue = false;

    private onLoginFunction: any;

    private loginWindow: any;

    errorMessage$: Observable<string> = this.store.select(AuthState.getErrorMessage);
    isClickHere$: Observable<boolean> = this.store.select(AuthState.getIsClickHere);
    isAdmin$: Observable<boolean> = this.store.select(AuthState.getIsAdmin);

    form: FormGroup = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required, Validators.minLength(8)]),
        remember: new FormControl(false),
    });

    variableValueTypeEnum = VariableValueTypeEnum;
    disabledTypeEnum = DisabledTypeEnum;

    isAdmin: boolean;

    readonly GOOGLE_LOGIN_ICON_PATH = './assets/design/icons/auth/google.svg';

    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        public router: Router,
        private route: ActivatedRoute,
        public socketService: SocketService,
        private preloaderService: PreloaderService,
        private zone: NgZone,
        public platform: Platform,
        private store: Store,
        private notificationService: NotificationsService,
    ) {
        this.route.data.pipe(first()).subscribe((data: Data) => {
            this.store.dispatch(new SetIsAdmin(data.isAdmin as boolean));
        });

        window.addEventListener('keydown', (event: KeyboardEvent) => {
            if (this.isContinue) {
                if (event.key === 'Enter') {
                    event.preventDefault();
                    this.login();
                }
            }
        });

        window.addEventListener('popstate', (e) => {
            if (e) {
                this.backToLogin();
            }
        });
    }

    ngOnInit(): void {
        this.isAdmin$.pipe(takeUntil(this.destroy)).subscribe((isAdmin) => {
            this.isAdmin = isAdmin;
        });

        if (this.socketService.isForceLogout) {
            this.notificationService.onEmit(TooltipStatusEnum.load, true, 'auth.login.forceLogout');
        }
    }

    ngOnDestroy(): void {
        this.preloaderService.destroyPreloader();
        this.destroy.next(true);
        this.destroy.complete();
    }

    async login(authToken?: string): Promise<void> {
        try {
            this.isWaitRequest = true;
            this.preloaderService.initPreloader(PreloaderWrappersEnum.login__page);
            const data = {
                login: this.form.value.email,
                password: this.form.value.password.trim(),
                token: authToken,
            };

            await this.store.dispatch(new AuthSingIn(data, this.isAdmin || this.router.url.includes('control'))).toPromise();

            const result = this.store.selectSnapshot(AuthState.getAuthStatus);
            switch (result.status) {
                case HTTP_STATUS.TWO_FACTOR_AUTHENTICATION_REQUIRED:
                    this.authentication = true;
                    this.preloaderService.destroyPreloader();
                    this.isWaitRequest = false;
                    return;
                case HTTP_STATUS.PASSWORD_IS_EXPIRED:
                    this.passwordExpired = true;
                    this.preloaderService.destroyPreloader();
                    this.isWaitRequest = false;
                    return;
                case HTTP_STATUS.NEED_CHANGE_PASSWORD:
                    this.preloaderService.destroyPreloader();
                    await this.router.navigate(['/login/control/change'], {
                        queryParams: { data: encodeURIComponent(JSON.stringify(data)) },
                    });
                    return;
            }

            if (result && result.status === HTTP_STATUS.SUCCESS) {
                this.store.dispatch(new SetSkeleton(true));
                this.socketService.isForceLogout = false;
                this.store.dispatch(new RemoveNote());
                this.store.dispatch(new SetUserToken(result.data.access_token));
                await this.socketService.connect();

                // this.store.dispatch(new SetIsAdminRoles(this.isAdmin));
                if (this.isAdmin || this.router.url.includes('control')) {
                    await this.store.dispatch(new GetRolePermissionsForAdmin()).toPromise();
                    await this.store.dispatch(new SetExpiredPopup(true, result.data));
                    const currentRole = this.store.selectSnapshot(PermissionsState.getRoles).find((f) => f.id === result.data.roleId);
                    const currentDeviceRole = currentRole?.permissions?.find((f) => f.resource === ResourceAction.DEVICES);
                    this.preloaderService.destroyPreloader();
                    await this.router.navigate([`/control/${currentDeviceRole?.isRead ?? currentRole.name === RolesService.rootRole ? 'devices' : 'profile'}`]);
                } else {
                    await this.store.dispatch(new GetRolePermissionsForUser()).toPromise();
                    await this.store.dispatch(new SetExpiredPopup(true, result.data));

                    !this.platform.ANDROID && !this.platform.IOS ? await this.router.navigate(['/device-dashboard']) : await this.router.navigate(['/mobile']);
                }
            }

            this.preloaderService.destroyPreloader();

            this.isWaitRequest = false;
        } catch (e) {
            // console.log(e);
            this.preloaderService.destroyPreloader();

            this.isWaitRequest = false;
        }
    }

    googleLogin(): void {
        if (this.router.url.includes('control') || this.isAdmin) {
            return;
        }

        this.loginWindow = window.open(`${window.location.origin}/api/auth/google`, '', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no');
        console.log('open window');

        this.onLoginFunction = async (event) => {
            const data: ApiResponse = JSON.parse(event.data);
            this.loginWindow.close();
            window.removeEventListener('message', this.onLoginFunction);

            if (data.status === HTTP_STATUS.ERROR) {
                this.zone.runOutsideAngular(() => {
                    setTimeout(() => {
                        alert('Error');
                    }, 600);
                });
                return;
            }

            this.store.dispatch(new SetUserToken(data.data.access_token));
            this.store.dispatch(new SetSkeleton(true));

            await this.socketService.connect();
            await this.store.dispatch(new GetRolePermissionsForUser()).toPromise();

            await this.router.navigate(['/device-dashboard']);
        };

        window.addEventListener('message', this.onLoginFunction);
    }

    moveForgotPage(): void {
        this.isAdmin ? this.router.navigate(['login/control/forgot']) : this.router.navigate(['login/forgot']);
    }

    moveToRegistrationPage(): void {
        this.router.navigate(['login/registration']);
    }

    async repeatConfirmEmail(): Promise<void> {
        this.store.dispatch(new ConfirmEmail(this.form.get('email').value, this.isAdmin));
    }

    async expiredPassword(): Promise<void> {
        this.isAdmin ? await this.store.dispatch(new ForgotAdminPassword(this.form.value.email)).toPromise() : await this.store.dispatch(new ForgotPassword(this.form.value.email)).toPromise();
        this.notificationService.onEmit(TooltipStatusEnum.update, false, 'auth.login.sendMessage');
        this.passwordExpired = false;
    }

    backToLogin(): void {
        this.form.setValue({ email: this.form.value.email, password: '', remember: false });
        this.isContinue = false;
    }
}
