import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { first } from 'rxjs/operators';
import { HTTP_STATUS } from 'src/app/app-shared-elements/_enums/status.enum';
import { PreloaderWrappersEnum } from 'src/app/app-shared-elements/_enums/preloader-wrappers.enum';
import { PreloaderService } from 'src/app/app-shared-elements/_services/preloader.service';
import { SocketService } from 'src/app/app-shared-elements/_services/socket.service';
import { GetRolePermissionsForAdmin } from 'src/app/app-shared-elements/_store/actions/permissions.actions';
import { VariableValueTypeEnum } from 'src/app/groups/_enums/variable-value-type.enum';
import { MustMatch } from '../_helpers/must-math.validator';
import { ValidatePassword } from '../_helpers/strong-validator-password';
import { AuthSingInControl, SetUserToken } from '../_store/actions/auth.actions';
import { AuthState } from '../_store/states/auth.state';
import { FormTypeEnum } from '../../app-shared-elements/_enums/form-type.enum';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
    formArray: { name: FormTypeEnum; title: string; placeholder: string; isVisible: boolean; validateName: VariableValueTypeEnum }[] = [
        {
            name: FormTypeEnum.password,
            title: 'auth.registration.password',
            placeholder: 'auth.registration.passwordPlaceholder',
            isVisible: false,
            validateName: VariableValueTypeEnum.password,
        },
        {
            name: FormTypeEnum.repeat,
            title: 'auth.registration.repeat',
            placeholder: 'auth.registration.repeatPlaceholder',
            isVisible: false,
            validateName: VariableValueTypeEnum.password,
        },
    ];

    formTypeEnum = FormTypeEnum;
    success = false;

    modelPassword = '';
    modelEmail = '';
    strength = -1;
    strengthEmail;
    isVisiblePassword = false;
    isVisiblePasswordRepeat = false;
    private alreadyExist = false;

    form: FormGroup;
    isEmptyForm = false;

    variableValueTypeEnum = VariableValueTypeEnum;

    isWaitRequest: boolean;

    // oldPassword;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private store: Store,
        private route: ActivatedRoute,
        private socketService: SocketService,
        private preloaderService: PreloaderService,
    ) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group(
            {
                password: new FormControl('', [Validators.required, ValidatePassword.bind(this)]),
                repeat: new FormControl('', Validators.required),
            },
            {
                validator: MustMatch('password', 'repeat'),
            },
        );
    }

    ngOnDestroy(): void {
        this.preloaderService.destroyPreloader();
    }

    lookChangePasswordInput(): void {
        if (!this.modelPassword.length) {
            this.strength = 0;
        }
    }

    async createPassword(): Promise<void> {
        try {
            this.preloaderService.initPreloader(PreloaderWrappersEnum.login__page);
            this.isWaitRequest = true;
            this.route.queryParamMap.pipe(first()).subscribe(async (param: ParamMap) => {
                const data = JSON.parse(decodeURIComponent(param.get('data')));
                await this.store
                    .dispatch(
                        new AuthSingInControl({
                            ...data,
                            recoveryPassword: this.form.value.password.trim(),
                        }),
                    )
                    .toPromise();
                const result = this.store.selectSnapshot(AuthState.getAuthStatus);

                if (result && result.status === HTTP_STATUS.SUCCESS) {
                    this.store.dispatch(new SetUserToken(result.data.access_token));
                    await this.socketService.connect();
                    // this.store.dispatch(new SetIsAdminRoles(true));
                    await this.store.dispatch(new GetRolePermissionsForAdmin()).toPromise();

                    await this.router.navigate(['/control/profile']);
                    return;
                }

                this.preloaderService.destroyPreloader();
                this.isWaitRequest = false;
                await this.router.navigate(['/login/control']);
            });
        } catch (e) {
            // console.log(e);
            this.preloaderService.destroyPreloader();
            this.isWaitRequest = false;
            await this.router.navigate(['/login/control']);
        }
    }

    moveToLogin(): void {
        this.router.navigate(['/login/control']);
    }
}
