import {ProfileState} from './states/profile.state';
import {SessionState} from './states/sessions.state';
import {AuthenticationState} from './states/authentication.state';
import {ProfileBlocksState} from './states/profile-blocks.state';

export const profileStates = [
    SessionState,
    ProfileState,
    AuthenticationState,
    ProfileBlocksState
];
