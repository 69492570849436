import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthState } from '../_store/states/auth.state';

@Component({
    selector: 'app-authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthenticationComponent implements OnInit {
    errorMessage$: Observable<string> = this.store.select(AuthState.getErrorMessage);

    authToken: string;

    @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() loginEvent: EventEmitter<string> = new EventEmitter<string>();

    constructor(private store: Store) {}

    ngOnInit(): void {}

    login(): void {
        this.loginEvent.emit(this.authToken);
    }

    close(): void {
        this.closeEvent.emit();
    }
}
