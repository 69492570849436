import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { HTTP_STATUS } from 'src/app/app-shared-elements/_enums/status.enum';
import { Store } from '@ngxs/store';
import { ForgotAdminPassword, ForgotPassword, SetIsAdmin } from '../_store/actions/auth.actions';
import { AuthState } from '../_store/states/auth.state';
import { first, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { TooltipStatusEnum } from '../../app-shared-elements/_enums/tooltip-status.enum';
import { NotificationsService } from '../../app-shared-elements/_services/notifications.service';

@Component({
    selector: 'app-forgot',
    templateUrl: './forgot.component.html',
    styleUrls: ['./forgot.component.scss'],
})
export class ForgotComponent implements OnInit {
    isAdmin$: Observable<boolean> = this.store.select(AuthState.getIsAdmin);
    private isAdmin: boolean;

    noSuchEmail = false;
    success = false;

    form: FormGroup = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
    });

    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private notificationService: NotificationsService,
        private store: Store,
    ) {
        this.route.data.pipe(first()).subscribe((data: Data) => {
            this.store.dispatch(new SetIsAdmin(data.isAdmin as boolean));
        });
    }

    ngOnInit(): void {
        this.isAdmin$.pipe(takeUntil(this.destroy)).subscribe((isAdmin) => {
            this.isAdmin = isAdmin;
        });
    }

    async resetPassword(): Promise<void> {
        this.isAdmin ? await this.store.dispatch(new ForgotAdminPassword(this.form.value.email)).toPromise() : await this.store.dispatch(new ForgotPassword(this.form.value.email)).toPromise();
        const result = this.store.selectSnapshot(AuthState.getAuthStatus);

        if (result.message) {
            this.notificationService.onEmit(TooltipStatusEnum.error, false);
            this.noSuchEmail = true;
        }

        if (result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false, 'auth.login.sendMessage');
            this.success = true;
        }
    }

    moveToLoginPage(): void {
        this.isAdmin ? this.router.navigate(['login/control']) : this.router.navigate(['login']);
    }
}
