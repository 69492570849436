<div class="login__page">
    <div class="login">
        <div class="login__wrapper">

            <ng-container *ngIf="!authentication && !passwordExpired">
                <h2 class="login__title" [translate]="!router.url.includes('control') && !(isAdmin$ | async)
                ? 'auth.login.title' : 'auth.login.titleControl'"></h2>
                <form class="login__form" [formGroup]="form" novalidate
                >
                    <div class="login__form-item">

                        <ng-container *ngIf="!isContinue; else email">
                            <label class="login__form-label" [translate]="'auth.login.login'"></label>
                            <input class="login__form-input"
                                   autocomplete="username"
                                   maxlength="70"
                                   [appInputValidate]="variableValueTypeEnum.email"
                                   [placeholder]="'auth.login.email' | translate"
                                   type="text"
                                   name="email"
                                   formControlName="email"
                            >
                        </ng-container>

                        <ng-template #email>
                            <span [ngStyle]="{fontWeight: 700}">{{ form.value.email }}</span>
                        </ng-template>

                    </div>

                    <ng-container *ngIf="isContinue">

                        <div class="login__form-item"
                        >
                            <label class="login__form-label" [translate]="'auth.login.password'"></label>
                            <input class="login__form-input"
                                   [readonly]="readOnly"
                                   autocomplete="current-password"
                                   maxlength="40"
                                   (focusin)="readOnly = false"
                                   [placeholder]="'auth.login.password' | translate"
                                   [type]="isVisiblePassword ? 'text' : 'password'"
                                   name="login" formControlName="password"
                            >
                            <div class="login__icon-eyes" (click)="isVisiblePassword = !isVisiblePassword">
                                <svg-icon
                                    [src]="!isVisiblePassword ? './assets/design/icons/eyes.svg' : './assets/design/icons/eyes-close.svg'"></svg-icon>
                            </div>
                            <p *ngIf="(errorMessage$ | async) && (errorMessage$ | async).length"
                               class="login__form-error">
                                <span [translate]="errorMessage$ | async"></span>
                                <span *ngIf="isClickHere$ | async">
                                <span *ngIf="isClickHere$ | async"
                                      class="login__click-here"
                                      [translate]="'auth.login.clickHere'"
                                      (click)="repeatConfirmEmail()"></span>


                                <span [translate]="'auth.login.goEmail'"></span>

                            </span>
                            </p>

                        </div>
                    </ng-container>

                    <div class="login__form-activity">

                        <div *ngIf="!isContinue"
                             class="login__form-activity-item">
                            <div class="login__form-activity-column">
                                <button class="login__form-sign-btn"
                                        autofocus
                                        [appDisabled]="{type: disabledTypeEnum.email,
                                        data: form.value.email}"
                                        (click)="isContinue = true"
                                        [translate]="'btns.continue'"></button>
                            </div>
                        </div>

                        <div *ngIf="isContinue"
                             class="login__form-activity-item">
                            <div class="login__form-activity-column">

                                <button (click)="backToLogin()"
                                        class="btn btn--light"
                                        [translate]="'btns.back'"
                                ></button>

                                <button class="login__form-sign-btn"
                                        autofocus
                                        [disabled]="!isAdmin && form.touched && (!form.valid || !form.controls['password'].value.length
                                        || !form.controls['password'].valid || !form.controls['email'].valid)"
                                        [class.login__form-sign-btn--disabled]="isWaitRequest"
                                        (click)="login()"
                                        [accessKey]="'enter'"
                                        [translate]="'btns.signIn'"></button>
                            </div>
                            <div *ngIf="!platform.IOS && !platform.ANDROID" class="login__form-activity-column">
                                <p class="login__form-forgot" (click)="moveForgotPage()"
                                   [translate]="'auth.login.forgot'"></p>
                            </div>
                        </div>

                        <!--                        <ng-container *ngIf="!isContinue">-->
                        <!--                            <div-->
                        <!--                                *ngIf="!platform.IOS && !platform.ANDROID && (!router.url.includes('control') && !(isAdmin$ | async))"-->
                        <!--                                class="login__form-activity-item">-->
                        <!--                                <div class="login__form-activity-column">-->
                        <!--                                    <div class="login__move login__move&#45;&#45;login">-->
                        <!--                                        <span [translate]="'auth.login.question'"></span>-->
                        <!--                                        <span (click)="moveToRegistrationPage()"-->
                        <!--                                              [translate]="'auth.login.signup'"></span>-->
                        <!--                                    </div>-->
                        <!--                                </div>-->
                        <!--                                <div *ngIf="(configurationServer$ | async)?.isGoogleAuthorization"-->
                        <!--                                     class="login__form-activity-column">-->
                        <!--                                    <p class="login__form-sign-with-title" [translate]="'auth.login.with'"></p>-->
                        <!--                                    <div class="login__form-sign-with-link" (click)="googleLogin()">-->
                        <!--                                        <svg-icon [src]="GOOGLE_LOGIN_ICON_PATH"></svg-icon>-->
                        <!--                                    </div>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                        </ng-container>-->

                    </div>
                </form>
            </ng-container>
            <ng-container *ngIf="authentication">
                <app-authentication (closeEvent)="authentication = false"
                                    (loginEvent)="login($event)"></app-authentication>
            </ng-container>

            <ng-container *ngIf="passwordExpired">
                <div class="expired">
                    <h1 class="expired__title" [translate]="'auth.login.expiredTitle'"></h1>
                    <p class="expired__text" [translate]="'auth.login.expiredMessage'"></p>
                    <div class="expired__btns">
                        <button (click)="passwordExpired = false" class="btn btn--light"
                                [translate]="'btns.back'"></button>
                        <button (click)="expiredPassword()" class="btn" [accessKey]="'enter'"
                                [translate]="'btns.send'"></button>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>

</div>
