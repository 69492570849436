import {MailingGroupState} from './states/mailing-group.state';
import {MailingRecipientState} from './states/mailing-recipient.state';
import {MailingGroupEditState} from './states/mailing-group-edit.state';
import {MailingSendersState} from './states/mailing-sender.state';
import {MailingGroupReportState} from './states/mailing-group-report.state';

export const mailingStore = [
    MailingRecipientState,
    MailingGroupState,
    MailingGroupEditState,
    MailingSendersState,
    MailingGroupReportState
];
