<div class="login">
    <div class="login__wrapper">
        <h2 class="login__title" [translate]="parseTypeToken === typeTokenEnum.SET_NEW_PASSWORD
        ? 'auth.reset.changeTitle' : 'auth.reset.title'"></h2>
        <form *ngIf="isValidToken; else invalidToken" class="login__form" [formGroup]="form" novalidate>

            <ng-container *ngFor="let item of formArray">

                <ng-container *ngIf="item.name === formTypeEnum.password">

                    <div class="login__form-item">
                        <label class="login__form-label" [translate]="item.title"></label>
                        <input
                            [type]="item.isVisible ? 'text' : 'password'"
                            [class.login__form-input--middle]="strength === 1"
                            [class.login__form-input--strong]="strength > 1"
                            class="login__form-input"
                            minlength="6"
                            maxlength="50"
                            [placeholder]="parseTypeToken === typeTokenEnum.SET_NEW_PASSWORD
                    ? ('auth.reset.setPasswordPlaceholder' | translate) : ('auth.reset.passwordPlaceholder' | translate)"
                            [formControlName]="item.name">
                        <div class="login__icon-eyes" (click)="item.isVisible = !item.isVisible">
                            <svg-icon
                                [src]="!item.isVisible ? './assets/design/icons/eyes.svg' : './assets/design/icons/eyes-close.svg'"></svg-icon>
                        </div>
                        <p class="login__form-error"
                           [class.login__form-error--middle]="strength === 1"
                           [class.login__form-error--default]="strength === -1"
                           [class.login__form-error--error]="strength === 0"
                           [class.login__form-error--success]="strength > 1">
                            <span *ngIf="strength < 1" [translate]="'auth.reset.passwordNote'"></span>
                            <span *ngIf="strength === 1" [translate]="'auth.reset.passwordBetter'"></span>
                            <span *ngIf="strength === 2" [translate]="'auth.reset.passwordBest'"></span>

                        </p>

                    </div>

                </ng-container>


                <ng-container *ngIf="item.name === formTypeEnum.repeat">

                    <div class="login__form-item">
                        <label class="login__form-label" [translate]="item.title">*</label>
                        <input class="login__form-input"
                               [placeholder]="parseTypeToken === typeTokenEnum.SET_NEW_PASSWORD
                       ? ('auth.reset.repeatSetPasswordPlaceholder' | translate) : ('auth.reset.repeatPlaceholder' | translate)"
                               [formControlName]="item.name"
                               minlength="6"
                               maxlength="50"
                               [type]="item.isVisible ? 'text' : 'password'">
                        <div class="login__icon-eyes" (click)="item.isVisible = !item.isVisible">
                            <svg-icon
                                [src]="!item.isVisible ? './assets/design/icons/eyes.svg' : './assets/design/icons/eyes-close.svg'"></svg-icon>
                        </div>
                        <p class="login__form-error"
                           *ngIf="form.controls['repeat'].invalid && form.controls['repeat'].touched"
                           [translate]="'auth.reset.repeatError'"></p>

                    </div>

                </ng-container>

            </ng-container>

            <div class="login__form-item" *ngIf="isTwoFactorAuth">
                <label class="login__form-label" for="auth"
                       [translate]="'profile.authenticationPopup.titleCode'"></label>
                <input [(ngModel)]="authToken"
                       [placeholder]="'auth.reset.twoAuthPlaceholder' | translate" type="text"
                       id="auth"
                       formControlName="twoAuth"
                       class="login__form-input">
            </div>

            <div class="login__form-activity">
                <div class="login__form-activity-item">
                    <div class="login__form-activity-column">
                        <button
                            [disabled]="!form.valid || !form.controls['password'].valid || !form.controls['repeat'].valid || isTwoFactorAuth && !authToken?.length"
                            class="login__form-sign-btn" (click)="reset()" [accessKey]="'enter'"
                            [translate]="parseTypeToken === typeTokenEnum.SET_NEW_PASSWORD
                            ? 'btns.setPassword' : 'btns.resumePassword'"></button>
                    </div>
                </div>
            </div>
        </form>
        <ng-template #invalidToken>
            <div class="login__invalid-token">
                <div class="login__invalid-token-message" [translate]="'auth.reset.invalidToken'"></div>
                <button class="login__invalid-token-btn btn btn--red"
                        [translate]="'btns.tryAgain'"
                        [accessKey]="'enter'"
                        (click)="moveToForgot()"></button>
            </div>
        </ng-template>

    </div>
</div>
