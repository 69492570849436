import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { TooltipStatusEnum } from '../../_enums/tooltip-status.enum';
import { AddNote, RemoveNote } from '../actions/note-notification.actions';
import { NoteInfoInterface } from '../../_interfaces/note-info.interface';

export interface NoteNotificationsModel {
    notes: NoteInfoInterface[];
}

const NOTE_NOTIFICATIONS_TOKEN = new StateToken<NoteNotificationsModel>('noteNotificationState');

@State<NoteNotificationsModel>({
    name: NOTE_NOTIFICATIONS_TOKEN,
    defaults: {
        notes: [],
    },
})
@Injectable()
export class NoteNotificationState {
    constructor() {}

    @Selector()
    static getNotes(state: NoteNotificationsModel): NoteInfoInterface[] {
        return state.notes;
    }

    @Action(AddNote)
    addNote(ctx: StateContext<NoteNotificationsModel>, payload: AddNote): void {
        const state = ctx.getState();
        if (state.notes.length > 1) {
            return;
        }

        if (state.notes.length === 1 && state.notes[0].status === TooltipStatusEnum.error) {
            return;
        }

        if (
            state.notes.length === 1 &&
            payload.data.status !== TooltipStatusEnum.error &&
            payload.data.status !== TooltipStatusEnum.sleep
        ) {
            ctx.setState({
                ...state,
                notes: [payload.data],
            });

            return;
        }

        ctx.setState({
            ...state,
            notes: [...state.notes, payload.data],
        });
    }

    @Action(RemoveNote)
    removeNote(ctx: StateContext<NoteNotificationsModel>, payload: RemoveNote): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            notes: state.notes.length > 1 && payload.info ? state.notes.filter((f) => f.status !== payload.info.status) : [],
        });
    }
}
