import { ArchiveChartInterface } from '../../_interfaces/ArchiveChart';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SetArchive } from '../actions/archive.actions';

export interface ArchiveStateModel {
    archive: ArchiveChartInterface;
}

const ARCHIVE_STATE_TOKEN = new StateToken<ArchiveStateModel>('archive');

@State<ArchiveStateModel>({
    name: ARCHIVE_STATE_TOKEN,
    defaults: {
        archive: null,
    },
})
@Injectable()
export class ArchiveState {
    constructor() {}

    @Selector()
    static getArchive(state: ArchiveStateModel): ArchiveChartInterface {
        return state.archive;
    }

    @Action(SetArchive)
    getArchive(ctx: StateContext<ArchiveStateModel>, payload: SetArchive): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            archive: payload.archive,
        });
    }
}
