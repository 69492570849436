import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class PostResponseInterceptor implements HttpInterceptor {

    private headersName = 'registratorId';
    private clearArray = ['/api/users', 'api/chat', 'api/configuration-server', 'api/share-registrators/inviteAccess'];

    constructor() { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        // if (request.method !== 'POST') return next.handle(request);
        const registratorHeaders = request.headers.get('registratorid');

        // if (!environment.production && (!registratorHeaders || !registratorHeaders.length) && !this.clearArray.includes(request.url)) {
        //     console.log('Didnt sent right headers', request);
        // }

        return next.handle(request);
    }
}
