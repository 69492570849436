import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CompressionService} from '../_services/compression.service';

@Injectable()
export class DecryptionInterceptorInterceptor implements HttpInterceptor {
    constructor() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                event = event.clone({body: this.modifyBody(event)});
            }
            return event;
        }));
    }

    private modifyBody(event: any): any {
        let data: any = {
            status: -1
        };

        try {
            data = CompressionService.decompressionJson(event.body);
            // console.log(data);
            // data = (data as string).split('').reverse().join('');
        } catch (e) {
            console.log('error decode', event.body);
        }


        return data;
    }

}
